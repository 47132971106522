import { SvgIconProps } from "@chhjit/react-components";
import { SvgIcon } from "@material-ui/core";
import { memo } from "react";

export const KebabMenuIcon = memo(
  ({ className, fontSize = 19, color = "#272B29" }: SvgIconProps) => {
    return (
      <SvgIcon
        className={className}
        style={{ fontSize }}
        width="21"
        height="15"
        viewBox="0 0 21 15"
        fill="none"
      >
        <path
          d="M1 7.33987H20"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 1.02615H20"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 13.6538H20"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
    );
  },
);
