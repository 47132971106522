import { Badge, Avatar as MuiAvatar, Typography } from "@material-ui/core";
import clsx from "clsx";
import { memo } from "react";

import { useStyles } from "./styles";
import { AvatarProps } from "./types";

export const Avatar = memo(
  ({ imageUrl, icon, avatarApproved, isMobile, className }: AvatarProps) => {
    const styles = useStyles();

    return (
      <div>
        <Badge
          badgeContent={
            icon ? (
              <div className={clsx(styles.avatarBadge, className)}>{icon}</div>
            ) : (
              <></>
            )
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <MuiAvatar
            src={imageUrl}
            classes={{
              root: isMobile ? styles.avatarMobile : styles.avatarDesktop,
            }}
          />
        </Badge>

        {avatarApproved && (
          <Typography variant="h5" className={styles.avatarApproved}>
            {avatarApproved}
          </Typography>
        )}
      </div>
    );
  },
);
