import { SvgIconProps } from "@chhjit/react-components";
import { SvgIcon } from "@material-ui/core";
import { memo } from "react";

export const BellIcon = memo(
  ({ className, color = "#272B29", fontSize = 16 }: SvgIconProps) => (
    <SvgIcon
      className={className}
      style={{ fontSize, fill: "none" }}
      width={25}
      height={26}
      viewBox="0 0 25 26"
    >
      <path
        d="M23 22H2C2 22 3.84211 20.0006 3.84211 9.01227C3.84211 -1.97606 20.7895 -1.36103 20.7895 9.01229C20.7895 19.3856 23 22 23 22Z"
        stroke={color}
        stroke-width="1.5"
      />
      <path
        d="M13 25C11.3125 25 10 23.625 10 22H16C16 23.625 14.625 25 13 25Z"
        stroke={color}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  ),
);
