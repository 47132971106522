import { colors, SvgIconProps } from "@chhjit/react-components";
import { SvgIcon } from "@material-ui/core";
import { memo } from "react";

export const BoldPencilIcon = memo(
  ({ className, color = colors.primary.main, fontSize = 16 }: SvgIconProps) => (
    <SvgIcon
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      style={{ fill: "none", fontSize: fontSize }}
      fill="currentColor"
    >
      <path
        d="M13.4159 2.58407C13.0442 2.21239 12.5664 2 12.0354 2C11.5044 2 11.0265 2.21239 10.6549 2.58407L2.95575 10.2832L2 13.9469L5.66372 12.9912L13.4159 5.34513C13.7876 4.97345 14 4.49558 14 3.9646C14 3.43363 13.7876 2.95575 13.4159 2.58407Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4426 2.84955L13.1506 5.55751"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.95581 10.2832L5.66378 12.9911"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  ),
);
